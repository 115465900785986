import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "vant/lib/index.css";
import { Lazyload } from "vant";

const app = createApp(App);
// 全局注册懒加载
app.use(Lazyload, {
  loading: "https://cdn-img.lewei.life/admin_1688983118076_0",
});
app.use(store).use(router).mount("#app");
