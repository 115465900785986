import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    // redirect: (to) => {
    //   return { path: "/travel/detail" };
    // },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HomeView/HomeView.vue"),
  },
  {
    path: "/travel/detail",
    name: "travel",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Travel/TravelPage.vue"),
  },
  // 兼容旧的url,所以保留两份
  {
    path: "/t/:id",
    name: "t",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Travel/TravelPage.vue"),
  },
  {
    path: "/agreement/:type",
    name: "RichTextView",
    component: () =>
      import(
        /* webpackChunkName: "rich" */ "../views/RichTextView/RichTextView.vue"
      ),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: process.env.VUE_APP_ROUTER,
  },
];
// console.log(process.env.VUE_ROUTER);
const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_ROUTER),
  routes,
});
export default router;
