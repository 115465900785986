import { SwiperImageItem } from "@/views/Travel/types";

const travel = {
  namespace: true,
  state: {
    travelId: "",
    index: -1, //当前选中的索引
    locationId: "",
    showButton: false,
    currentTravelExtinfo: "",
    equipmentType: "",
    showDetailInfo: true,
    initHistoryLength: 0,
    isXiaoMi: false,
  },
  mutations: {
    changeState(state: any, param: { key: string; value: any }) {
      state[param.key] = param.value;
    },
  },
};
export default travel;
